import React, { useEffect } from "react"
import { useAuth0 } from "@utils/auth"
import { graphql, navigate } from "gatsby"
import Splash from "@layouts/splash"
import Seo from "@elements/Seo"
import Surface from "@elements/Surface"
import Block from "@global/layout/Block"
import Button from "@elements/Button"

const IndexPage = ({ data }) => {
  const {
    isAuthenticated,
    user,
    loading,
    loginWithRedirect,
    subscriptions,
    hasActiveSubscriptions
  } = useAuth0()

  useEffect(() => {
    if (hasActiveSubscriptions === true) {
      navigate("/home")
    }
  }, [subscriptions, hasActiveSubscriptions])

  const {
    heading,
    lead,
    loginMessage,
    signupCTA,
    loginCTA
  } = data.content.index

  return (
    <Splash>
      <Seo
        title={data.content.index.meta.title}
        description={data.content.index.meta.desc}
      />
      <Surface
        heading={isAuthenticated ? `Welcome Back,` : heading}
        lead={!isAuthenticated ? lead : null}
        user={
          user
            ? user?.[`${process.env.GATSBY_AUTH0_NAMESPACE}permissions`]
                .customer.first_name
            : ""
        }
        fontSize={"lg"}
      >
        {!loading && !isAuthenticated && (
          <>
            <Button
              type="button"
              text={signupCTA}
              onClick={() => loginWithRedirect({ screen_hint: "signup" })}
            />
            <Block padding={"double-top-single-bottom"}>{loginMessage}</Block>
            <Button
              type="button"
              text={loginCTA}
              secondary={true}
              onClick={() =>
                loginWithRedirect({
                  appState: `${window.location.pathname}home`
                })
              }
            />
          </>
        )}

        {!loading && isAuthenticated && (
          <>
            <Spinner />
            <p>{"We're loading your custom DediKate page"}</p>
          </>
        )}

        {loading && <Spinner />}
      </Surface>
    </Splash>
  )
}

export default IndexPage

export const query = graphql`
  {
    content: contentYaml(language: { eq: "en-NZ" }) {
      index {
        meta {
          title
          desc
        }
        heading
        lead
        loginMessage
        signupCTA
        loginCTA
      }
    }
  }
`

const Spinner = () => (
  <svg version="1.1" viewBox="0 0 32 32" width="32px" height="32px" fill="#999">
    <path
      opacity=".25"
      d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
    />
    <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 16 16"
        to="360 16 16"
        dur="0.8s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
)
